export function trsformKayVal (formData, templateId) {
  let result = []
  let map = objToStrMap(formData)
  for (let [key, value] of map) {
    let temp = {}
    temp.key = key
    temp.value = value
    temp.medTemplateItemId = templateId
    result.push(temp)
  }
  return result
}

export function trsformKayValByDetail (itemList) {
  let temp = {}
  for (let i in itemList) {
    temp[itemList[i].key] = itemList[i].value
  }
  return temp
}

export function trsformKayValTy (data) {
  let result = []
  let map = objToStrMap(data)
  for (let [key, value] of map) {
    let temp = {}
    temp.key = key
    temp.value = value
    result.push(temp)
  }
  console.log(JSON.stringify(result))
  return result
}

export function getCurrentDate () {
  var date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  return year + '-' + month + '-' + day
}

export function getCurrentTime () {
  var date = new Date()
  let hour = date.getHours()
  let min = date.getMinutes()
  let sec = date.getSeconds()
  return hour + ':' + min + ':' + sec
}

export function getCurrentDateTime () {
  return getCurrentDate() + ' ' + getCurrentTime()
}

export function jsonAddJson (jsonObj1, jsonObj2) {
  let map = objToStrMap(jsonObj1)
  for (let [key, value] of map) {
    jsonObj2['' + key] = value
  }
  return jsonObj2
}

export function DateCha (str1, str2, str3) {
  var d1 = new Date(str1);
  var d2 = new Date(str2);
  if (str3 === 'min') {
    return parseInt(d2 - d1) / 1000 / 60
  } else if (str3 === 'hour') {
    return parseInt(d2 - d1) / 1000 / 60 / 60
  } else if (str3 === 'day') {
    return parseInt(d2 - d1) / 1000 / 60 / 60 / 24
  } else if (str3 === 'dayCurrent') {
    d2 = dateFormat(new Date(), 'yyyy-MM-dd');
    d1 = dateFormat(new Date(str1), 'yyyy-MM-dd');

    var separator = '-'; // 日期分隔符
    var startDates = d1.split(separator);
    var endDates = d2.split(separator);
    var startDate = new Date(startDates[0], startDates[1] - 1, startDates[2]);
    var endDate = new Date(endDates[0], endDates[1] - 1, endDates[2]);
    return parseInt(Math.abs(endDate - startDate) / 1000 / 60 / 60 / 24) + 1;// 把相差的毫秒数转换为天数
    // return parseInt(parseInt(d2 - d1) / 1000 / 60 / 60 / 24)
  }
}

export function randomString (len) {
  let pwd = ''
  len = len || 32
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'   // ****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function objToStrMap (obj) {
  let strMap = new Map()
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k])
  }
  return strMap
}

export function dateFormat (date, sFormat) {
  if (date === undefined) {
    return
  }
  let time = {
    Year: 0,
    TYear: '0',
    Month: 0,
    TMonth: '0',
    Day: 0,
    TDay: '0',
    Hour: 0,
    THour: '0',
    hour: 0,
    Thour: '0',
    Minute: 0,
    TMinute: '0',
    Second: 0,
    TSecond: '0',
    Millisecond: 0
  }
  time.Year = date.getFullYear()
  time.TYear = String(time.Year).substr(2)
  time.Month = date.getMonth() + 1
  time.TMonth = time.Month < 10 ? '0' + time.Month : String(time.Month)
  time.Day = date.getDate()
  time.TDay = time.Day < 10 ? '0' + time.Day : String(time.Day)
  time.Hour = date.getHours()
  time.THour = time.Hour < 10 ? '0' + time.Hour : String(time.Hour)
  time.hour = time.Hour < 13 ? time.Hour : time.Hour - 12
  time.Thour = time.hour < 10 ? '0' + time.hour : String(time.hour)
  time.Minute = date.getMinutes()
  time.TMinute = time.Minute < 10 ? '0' + time.Minute : String(time.Minute)
  time.Second = date.getSeconds()
  time.TSecond = time.Second < 10 ? '0' + time.Second : String(time.Second)
  time.Millisecond = date.getMilliseconds()

  return sFormat.replace(/yyyy/ig, String(time.Year))
    .replace(/yyy/ig, String(time.Year))
    .replace(/yy/ig, time.TYear)
    .replace(/y/ig, time.TYear)
    .replace(/MM/g, time.TMonth)
    .replace(/M/g, String(time.Month))
    .replace(/dd/ig, time.TDay)
    .replace(/d/ig, String(time.Day))
    .replace(/HH/g, time.THour)
    .replace(/H/g, String(time.Hour))
    .replace(/hh/g, time.Thour)
    .replace(/h/g, String(time.hour))
    .replace(/mm/g, time.TMinute)
    .replace(/m/g, String(time.Minute))
    .replace(/ss/ig, time.TSecond)
    .replace(/s/ig, String(time.Second))
    .replace(/fff/ig, String(time.Millisecond))
}

/**
 * 是否为空 空格串也是空
 * @param value 值
 */
export function isEmpty (value) {
  return value === '' || value === null || value === undefined || (typeof value === 'string' && value.length === 0) || new RegExp('^[ ]+$').test(value)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^((0\d{2,3}-\d{7,8})|(1[345678]\d{9}))$/.test(s);
}
